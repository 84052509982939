/**
 * Import packages
 */
import { createRouter, createWebHistory } from 'vue-router';
// import versionGuard from './guards/version';
import authGuard from './guards/auth';
import storeGuard from './guards/store';

import { useAppStore } from '@/stores/app';

/**
 * Define routes
 */
const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect: { name: 'Roster', query: { page: 1, 'sort-column': 'rank', 'sort-descending': false } },
    redirect: { name: 'Roster' },
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/roster',
    name: 'Roster',
    component: () => import('@/views/Roster.vue'),
    meta: {
      title: 'Roster',
    },
  },
  {
    path: '/member',
    sensitive: true,
    name: 'Member',
    component: () => import('@/views/Member.vue'),
    meta: {
      title: 'Member',
    },
    children: [
      {
        path: ':member?',
        sensitive: true,
        name: 'Member/Detail',
        component: () => import('@/views/MemberDetail.vue'),
        meta: {
          title: 'Member - Detail',
          showBackButton: true,
        },
      },
    ],
  },
  {
    path: '/raids',
    name: 'Raids',
    component: () => import('@/views/Raids.vue'),
    meta: {
      title: 'Raids',
      scrollToTop: true,
    },
  },
  {
    path: '/raiders',
    name: 'Raiders',
    component: () => import('@/views/Raiders.vue'),
    meta: {
      title: 'Raiders',
    },
  },
  {
    path: '/dkp',
    name: 'DKP',
    component: () => import('@/views/Dkp.vue'),
    meta: {
      title: 'DKP',
    },
  },
  {
    path: '/loot',
    name: 'Loot',
    component: () => import('@/views/Loot.vue'),
    meta: {
      title: 'Loot',
    },
  },
  {
    path: '/rankings',
    name: 'Rankings',
    component: () => import('@/views/Rankings.vue'),
    meta: {
      title: 'Rankings',
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/views/Reports.vue'),
    meta: {
      title: 'Reports',
    },
  },
  {
    path: '/reports/:code',
    sensitive: true,
    name: 'Report',
    component: () => import('@/views/Report.vue'),
    meta: {
      title: 'Report',
    },
  },
  {
    path: '/reports/:code/:fightId?',
    sensitive: true,
    name: 'Report/Detail',
    component: () => import('@/views/Report.vue'),
    meta: {
      title: 'Report',
    },
  },
  {
    path: '/admin',
    name: 'Admin/Dashboard',
    component: () => import('@/views/admin/Index.vue'),
    meta: {
      title: 'Admin',
      requiresAuth: true,
    },
    children: [
      {
        path: 'login',
        name: 'Admin/Login',
        component: () => import('@/views/Login.vue'),
        meta: {
          title: 'Login',
        },
      },
      {
        path: 'register',
        name: 'Admin/Register',
        component: () => import('@/views/Register.vue'),
        meta: {
          title: 'Register',
          requiresAuth: true,
        },
      },
      {
        path: 'roster',
        name: 'Admin/Roster',
        component: () => import('@/views/admin/Roster.vue'),
        meta: {
          title: 'Admin - Roster',
          requiresAuth: true,
        },
      },
      {
        path: 'report',
        name: 'Admin/Report',
        component: () => import('@/views/admin/Report.vue'),
        meta: {
          title: 'Admin - Report',
          requiresAuth: true,
        },
      },
      {
        path: 'dkp',
        name: 'Admin/Dkp',
        component: () => import('@/views/admin/Dkp.vue'),
        meta: {
          title: 'Admin - DKP',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: 'Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_URL),

  linkActiveClass: 'active',
  linkExactActiveClass: 'main-menu__item__link--active',

  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === 'Report/Detail') {
    document.getElementById('main').scrollIntoView({ top: 0, behavior: 'smooth' });
  } else {
    document.getElementById('header').scrollIntoView({ top: 0, behavior: 'smooth' });
  }

  const appStore = useAppStore();

  to.meta.showBackButton ? (appStore.showBackButton = true) : (appStore.showBackButton = false);

  next();
});

router.beforeEach(authGuard);
// router.beforeEach(versionGuard);
router.beforeEach(storeGuard);

export default router;
