/**
 * Import packages
 */
import { defineStore, acceptHMRUpdate } from 'pinia';
import { countBy, groupBy, isEmpty, shuffle } from 'lodash';
// https://lodash.com/docs/

/**
 * Import apis
 */
import charactersAPI from '@/api/characters';

/**
 * Functions
 */
const countItemInArray = (myItem, myArray) => {
  let counter = 0;

  let myClass = {};

  for (let item of myArray) {
    if (item == myItem) {
      counter++;
    }
  }
  myClass['count'] = counter;
  myClass['class'] = myItem.charAt(0).toUpperCase() + myItem.slice(1);

  return myClass;
};

/**
 * Define Store
 */
export const useCharacterStore = defineStore(
  {
    /**
     * Store Name
     */
    id: 'character',

    historyEnabled: true,

    /**
     * States
     */
    state: () => ({
      delimiter: ',',
      dataTextarea: [],
      dataFile: [],
      characters: [],
      raiders: [],
      charactersNames: [],
      character: {},
      characterMessage: '',
      isCharacterLoaded: null,
      error: [],
      isLoading: true,
      isLoadingCharacter: true,
    }),

    /**
     * Getters
     */
    getters: {
      // getAllCharactersDetails(state) {
      //   return state.charactersDetails.sort((a, b) => {
      //     return b.deaths.length - a.deaths.length;
      //   });
      // },

      getCharacters(state) {
        return state.characters;
      },

      getCharacterDeaths(state) {
        if (!isEmpty(state.character)) return [].concat(...state.character.deaths.map(obj => obj.deaths));
        return [];
      },

      // countCharacterDeaths() {
      //   if (this.getCharacterDeaths.length === 0) return [];

      //   let newArray = this.getCharacterDeaths.filter(item => item.ability?.name);
      //   return newArray
      //     .reduce((acc, cur) => {
      //       let found = acc.find(item => item.ability.name === cur.ability.name);
      //       if (found) {
      //         found.count++;
      //       } else {
      //         acc.push({ ability: cur.ability, count: 1 });
      //       }
      //       return acc;
      //     }, [])
      //     .sort((a, b) => {
      //       return b.count - a.count;
      //     });
      // },

      // getCharacters(state) {
      //   this.newCharacters = [...state.characters];
      // },

      getCharacterStandings(state) {
        return state.characters
          .filter(obj => obj.standings && obj.standings.spent > 0)
          .map(obj => {
            const { name, standings, race, sex, class: characterClass, id, roles, guild_info } = obj;
            let role = null;

            if (roles && roles.length > 0) {
              role = roles.reduce((prev, curr) => (curr.count > prev.count ? curr : prev)).role;
            }

            return {
              name,
              points: standings.points,
              spent: standings.spent,
              race,
              sex,
              class: characterClass,
              id,
              role,
              rank: guild_info.rank,
            };
          });
      },

      groupedCharacterNames1: state => {
        const grouped = groupBy(state.charactersNames, character => character.class);
        const sorted = shuffle(Object.keys(grouped));
        return sorted;
      },

      groupedCharacterNames: state => {
        const grouped = groupBy(state.charactersNames, character => character.class);
        const sorted = shuffle(Object.keys(grouped));
        let inOrder = {};
        sorted.forEach(key => (inOrder[key] = grouped[key]));
        return inOrder;
      },

      totalSpentPoints: state =>
        state.characters.filter(character => character.standings !== null).reduce((p, c) => p + c.standings?.spent, 0),

      getNames(state) {
        return [...new Set(state.characters.map(character => character.name))];
      },

      getMaxLevelOnlyCharacters(state) {
        return state.characters.filter(character => character.level == 40);
      },

      getClasses(state) {
        return [...new Set(state.characters.map(character => character.class))];
      },

      getRoles(state) {
        return [...new Set(state.characters.map(character => character.specs))];
      },

      getRolesCount(state) {
        const rolesCount = {};

        // Iterate through each character and count the roles
        state.characters.forEach(character => {
          character.specs.forEach(spec => {
            const role = spec.role;
            if (role) {
              rolesCount[role] = (rolesCount[role] || 0) + 1;
            }
          });
        });

        // Convert the rolesCount object to an array of objects
        const result = Object.keys(rolesCount).map(role => ({
          role,
          count: rolesCount[role],
        }));

        return result;
      },

      getAllClasses(state) {
        return state.characters.map(character => character.class);
      },

      getAllRoles(state) {
        return state.characters.filter(character => character.roles[0]).map(character => character.roles[0]?.role);
      },

      getAllClassesMaxLevelOnly() {
        return this.getMaxLevelOnlyCharacters.map(character => character.class);
      },

      getAllRolesMaxLevelOnly() {
        return this.getMaxLevelOnlyCharacters.map(character => character.roles[0]?.role);
      },

      countClasses() {
        const copyItems = [];
        this.getClasses.forEach(item => copyItems.push(countItemInArray(item, this.getAllClasses)));
        return copyItems;
      },

      countByClasses: state => {
        return countBy(state.characters, character => character.class);
      },

      countByClassesMaxLevelOnly() {
        return countBy(this.getMaxLevelOnlyCharacters, character => character.class);
      },

      countClassesMaxLevelOnly() {
        const copyItems = [];
        this.getClasses.forEach(item => copyItems.push(countItemInArray(item, this.getAllClassesMaxLevelOnly)));
        return copyItems;
      },

      countRoles() {
        const copyItems = [];
        this.getRoles.forEach(item => copyItems.push(countItemInArray(item, this.getAllRoles)));
        return copyItems;
      },

      countRolesMaxLevelOnly() {
        const copyItems = [];
        this.getRoles.forEach(item => copyItems.push(countItemInArray(item, this.getAllRolesMaxLevelOnly)));
        return copyItems;
      },

      getRanks(state) {
        return [...new Set(state.characters.map(character => character.guild_info.rank))];
      },

      getRanksOnlyRaiders(state) {
        return [...new Set(state.characters.map(character => character.guild_info.rank))];
      },

      getAllRanks(state) {
        return state.characters.map(character => character.guild_info.rank);
      },

      getAllRanksMaxLevelOnly() {
        return this.getMaxLevelOnlyCharacters.map(character => character.guild_info.rank);
      },

      countRanks() {
        const copyItems = [];
        this.getRanks.forEach(item => copyItems.push(countItemInArray(item, this.getAllRanks)));
        return copyItems;
      },

      countRanksMaxLevelOnly() {
        const copyItems = [];
        this.getRanksOnlyRaiders.forEach(item => copyItems.push(countItemInArray(item, this.getAllRanks)));
        return copyItems;
      },
    },

    /**
     * Actions
     */
    actions: {
      async saveCharacterDetails(characterDetails) {
        try {
          const response = await charactersAPI.saveCharacterDetails(characterDetails);
          console.log(response);
        } catch (error) {
          console.log(error);
          throw error;
        }
      },

      async scanCharacters() {
        try {
          const response = await charactersAPI.scan();
          console.log(response);
        } catch (error) {
          console.log(error);
          throw error;
        }
      },

      async scanCharactersDeaths(code) {
        try {
          const response = await charactersAPI.scanDeaths(code);
          console.log(response);
        } catch (error) {
          console.log(error);
          throw error;
        }
      },

      async scanCharactersGear(code) {
        try {
          const response = await charactersAPI.scanGear(code);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },

      async scanCharactersGuildInfo() {
        try {
          const response = await charactersAPI.scanGuildInfo();
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },

      async scanCharactersLoot(date) {
        try {
          const response = await charactersAPI.scanLoot(date);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },

      async scanCharactersPoints(date) {
        try {
          const response = await charactersAPI.scanPoints(date);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },

      async scanCharactersProfessions(code) {
        try {
          const response = await charactersAPI.scanProfessions(code);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },

      async scanCharactersRankings(code) {
        try {
          const response = await charactersAPI.scanRankings(code);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },

      async scanCharactersStandings() {
        try {
          const response = await charactersAPI.scanStandings();
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },

      async scanCharactersStats(code) {
        try {
          const response = await charactersAPI.scanStats(code);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      },

      async getCharacter(name) {
        try {
          const response = await charactersAPI.getCharacter(name);
          this.isLoading = true;
          this.character = response.data['data'];
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      },

      async getCharacterDetails(name) {
        try {
          const response = await charactersAPI.getCharacterDetails(name);

          this.characterMessage = response.data.message;
          this.isCharacterLoaded = response.data.success;
          this.character = response.data['data'];
          this.isLoading = false;
        } catch (error) {
          console.log(error);
        }
      },

      async getAllCharactersWithDetails() {
        this.isLoading = true;

        try {
          const response = await charactersAPI.getAllCharactersWithDetails();

          setTimeout(() => {
            this.characters = response.data['data'];
            this.isLoading = false;
          }, 250);
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      },

      async getAllRaidersWithDetails() {
        try {
          const response = await charactersAPI.getAllRaidersWithDetails();
          this.raiders = response.data['data'];
        } catch (error) {
          console.log(error);
        }
      },

      async getAllCharactersNames() {
        try {
          const response = await charactersAPI.getAllCharactersNames();
          this.charactersNames = response.data['data'];
        } catch (error) {
          console.log(error);
        }
      },

      findByName(name) {
        if (this.charactersNames.length > 0) {
          return this.charactersNames.find(member => member.name === name);
        } else {
          return null;
        }
      },
    },
  },
  {
    persist: true,
  }
);

/**
 * Import HMR
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCharacterStore, import.meta.hot));
}
