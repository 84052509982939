<script setup>
import { defineComponent } from 'vue';

import icons from '@/assets/icons/icons.json';

defineComponent({
  name: 'VIcon',
});

defineProps({
  name: {
    type: String,
    required: true,
    default: 'circle-duotone',
  },
});

const getIcon = name => {
  const s = icons.icons?.[name];
  if (s?.body) return s.body;
  else if (s?.parent) return getIcon(s.parent);
  else return null;
};
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256" width="1em" height="1em" class="icon">
    <g v-html="getIcon(`${name}`)" />
  </svg>
</template>
