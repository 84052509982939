<script setup>
import { defineComponent } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'AdminMenu',
});

/**
 * Variables
 */
const menuItems = [{ name: 'Admin', routerName: 'Admin/Dashboard', icon: 'user-circle-gear-duotone' }];
</script>

<template>
  <ul class="main-menu main-menu--admin">
    <MainMenuItem :menu-items="menuItems" />
  </ul>
</template>
