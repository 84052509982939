import { defineStore, acceptHMRUpdate } from 'pinia';

import text from '@/stores/_text';

import authAPI from '@/api/auth';

import { useAppStore } from '@/stores/app';

export const useAuthStore = defineStore(
  {
    id: 'auth',

    state: () => ({
      isAuthenticated: false,
      user: null,
      notifications: [],
      token: null,
      returnUrl: null,
    }),

    actions: {
      async fetchUser() {
        if (this.user) return;

        try {
          const response = await authAPI.getUser();
          this.user = response.data.user;
          this.token = localStorage.getItem(text.AUTH_TOKEN_KEY_NAME);
        } catch (error) {
          this.user = null;
          this.clearToken();
          this.$router.push({ name: 'Admin/Login' });
        }
      },

      async register(credentials) {
        try {
          const response = await authAPI.register(credentials);

          this.setMessage(response.data.message);

          await this.login(credentials);
        } catch (error) {
          this.setMessage(JSON.parse(error.response.data));
          this.clearToken();
        }
      },

      async login(credentials) {
        try {
          const response = await authAPI.login(credentials);

          const { token, message, success } = response.data;

          this.setMessage(message, success ? 'success' : 'error');

          this.setToken(token, 3600);

          const route = this.returnUrl ? { name: this.returnUrl } : { name: 'Admin/Dashboard' };
          this.$router.push(route);
        } catch (error) {
          const { message, success } = error.response.data;

          this.setMessage(message, success ? 'success' : 'error');

          this.clearToken();
        }
      },

      async logout() {
        try {
          const response = await authAPI.logout();

          const { message, success } = response.data;

          this.setMessage(message, success ? 'success' : 'error');

          this.$router.push({ name: 'Admin/Login' });
        } catch (error) {
          this.setMessage(error.response);
        } finally {
          this.clearUser();
          this.clearToken();
        }
      },

      setUser(user) {
        this.user = user;
      },

      clearUser() {
        this.user = null;
      },

      setMessage(message, type = 'info') {
        const appStore = useAppStore();

        // Push a new notification to the array
        this.notifications.push({ message: message, type: type });

        // Remove the notification after appToastTimeout
        setTimeout(() => {
          // Find the index of the added notification
          const indexToRemove = this.notifications.findIndex(
            notification => notification.message === message && notification.type === type
          );

          // Remove the notification if found
          if (indexToRemove !== -1) {
            this.notifications.splice(indexToRemove, 1);
          }
        }, appStore.appToastTimeout);
      },

      clearMessage() {
        this.message = null;
      },

      setToken(token, expiresIn) {
        this.token = `Bearer ${token}`;

        // Convert expiresIn to milliseconds and calculate expiration timestamp
        const expirationTimestamp = Date.now() + expiresIn * 1000;

        // You may want to persist the token in local storage or cookies
        localStorage.setItem(text.AUTH_TOKEN_KEY_NAME, this.token);
        localStorage.setItem(text.AUTH_EXPIRATION_KEY_NAME, expirationTimestamp);
      },

      clearToken() {
        this.token = null;
        // Clear the persisted token from local storage or cookies
        localStorage.removeItem(text.AUTH_TOKEN_KEY_NAME);
        localStorage.removeItem(text.AUTH_EXPIRATION_KEY_NAME);
      },

      checkAuthTokenExpiration() {
        const currentTimestamp = Date.now();
        const expirationTimestamp = localStorage.getItem(text.AUTH_EXPIRATION_KEY_NAME);

        if (currentTimestamp < Number(expirationTimestamp)) {
          localStorage.setItem(text.AUTH_EXPIRATION_KEY_NAME, currentTimestamp + 3600 * 1000);
          this.isAuthenticated = true;
          this.fetchUser();
        } else {
          this.isAuthenticated = false;
        }
      },
    },
  },
  {
    persist: true,
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
