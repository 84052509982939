import { useAppStore } from '@/stores/app';

export default function storeGuard(to, from, next) {
  const appStore = useAppStore();

  appStore.$patch({
    page: {
      title: 'member' in to.params ? to.params.member : to.meta.title,
      path: to.path,
      pathName: to.name,
    },
  });

  next();
}
