import Api from '.';

export default {
  getCharacters() {
    return Api().get('/characters');
  },
  getAllCharactersWithDetails() {
    return Api().get('/characters/details');
  },
  getAllRaidersWithDetails() {
    return Api().get('/characters/raiders/details');
  },
  getAllCharactersNames() {
    return Api().get('/characters/names');
  },
  getCharacter(name) {
    return Api().get(`/characters/${name}`);
  },
  getCharacterDetails(name) {
    return Api().get(`/characters/${name}/details`);
  },
  saveCharacterDetails(data) {
    return Api().post('/characters/details', data);
  },
  scan() {
    return Api().get('/characters/scan');
  },
  scanDeaths(code) {
    return Api().get(`/characters/scan/deaths/${code}`);
  },
  scanGear(code) {
    return Api().get(`/characters/scan/gear/${code}`);
  },
  scanGuildInfo() {
    return Api().get(`/characters/scan/guild-info`);
  },
  scanLoot(date) {
    return Api().get(`/characters/scan/loot/${date}`);
  },
  scanPoints(date) {
    return Api().get(`/characters/scan/points/${date}`);
  },
  scanProfessions() {
    return Api().get(`/characters/scan/professions`);
  },
  scanRankings(code) {
    return Api().get(`/characters/scan/rankings/${code}`);
  },
  scanStandings() {
    return Api().get(`/characters/scan/standings`);
  },
  scanStats(code) {
    return Api().get(`/characters/scan/stats/${code}`);
  },
};
