<script setup>
import { ref, watch } from 'vue';
import { isEmpty } from 'lodash';

const props = defineProps({
  notifications: {
    type: Array,
    required: false,
    default: null,
  },
  hasCloseButton: {
    type: Boolean,
    required: false,
    default: true,
  },
  hideProgressBar: {
    type: Boolean,
    required: false,
    default: false,
  },
  progressBarTimeout: {
    type: Number,
    required: false,
    default: 5000,
  },
});

let notificationsMessage = ref(props.notifications);

watch(props, () => {
  notificationsMessage.value = props.notifications;
});

const setIcon = name => {
  switch (name) {
    case 'info':
      return 'info-fill';
    case 'error':
      return 'warning-fill';
    case 'success':
      return 'check-circle-fill';
    default:
      return 'info-fill';
  }
};

const closeNotification = index => {
  if (index !== -1) {
    notificationsMessage.value.splice(index, 1);
  }
};
</script>

<template>
  <div v-if="!isEmpty(notifications)" class="notifications">
    <div
      v-for="(notification, index) in notificationsMessage"
      :key="index"
      :class="[`notifications__box--${notification.type}`, 'notifications__box']"
    >
      <VIcon :name="setIcon(notification.type)" class="notifications__box__icon" />
      <span class="notifications__box__message">{{ notification.message }}</span>
      <TheNotificationProgressBar
        v-if="!hideProgressBar"
        class="notifications__box__progress-bar"
        :progress-bar-timeout="progressBarTimeout"
      />
      <button v-if="hasCloseButton" class="notifications__box__close-button" @click="closeNotification(index)">
        <VIcon name="x-bold" class="notifications__box__close-button__icon" />
      </button>
    </div>
  </div>
</template>
