<script setup>
import { defineComponent } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'TheMain',
});
</script>

<template>
  <main id="main" class="app__main">
    <div class="app__main__inner">
      <router-view v-slot="{ Component, route }">
        <transition name="page" mode="out-in">
          <component :is="Component" :key="route.name" />
        </transition>
      </router-view>
    </div>
  </main>
</template>
