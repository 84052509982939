<script setup>
import { computed, onMounted } from 'vue';
import { useHead } from '@vueuse/head';
import { useBreakpoints } from '@vueuse/core';

import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';

const appStore = useAppStore();
const authStore = useAuthStore();

const breakpoints = useBreakpoints({
  mobile: 320,
  tablet: 480,
  widetablet: 832,
  desktop: 1088,
  widescreen: 1344,
});

const appName = computed(() => appStore.appName);
const appTitle = computed(() => appStore.appTitle);
const appDescription = computed(() => appStore.appDescription);
const appToastTimeout = computed(() => appStore.appToastTimeout);
const authNotifications = computed(() => authStore.notifications);

useHead({
  title: appTitle,
  meta: [
    { name: 'description', content: appDescription },

    { name: 'author', content: 'fery.sk' },

    { name: 'twitter:card', content: appTitle },
    { name: 'twitter:title', content: appName },
    { name: 'twitter:description', content: appDescription },
    // { name: 'twitter:image', content: getImageUrl('first_ud', 'webp') },

    { property: 'og:title', content: appName },
    { property: 'og:site_name', content: appTitle },
    { property: 'og:type', content: 'website' },
    // { property: 'og:image', content: getImageUrl('first_ud', 'webp') },
    { property: 'og:description', content: appDescription },
  ],
});

onMounted(() => {
  appStore.isMobile = breakpoints.between('mobile', 'tablet').value;
  appStore.isTablet = breakpoints.between('tablet', 'widetablet').value;
});
</script>

<template>
  <TheNavigation />
  <TheHeader />
  <TheMain />
  <TheFooter />
  <TheNotification :notifications="authNotifications" :progress-bar-timeout="appToastTimeout" />
</template>

<style lang="scss">
@import '@/assets/scss/app';
</style>
