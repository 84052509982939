<script setup>
import { defineComponent, computed } from 'vue';

import { useAuthStore } from '@/stores/auth';

/**
 * Component Name
 */
defineComponent({
  name: 'TheNavigation',
});

/**
 * Variables
 */
const authStore = useAuthStore();

/**
 * Computed Variables
 */
const isAuthenticated = computed(() => {
  return authStore.isAuthenticated;
});
</script>

<template>
  <nav class="app__navigation">
    <div class="app__navigation__inner">
      <MainMenu />
      <AdminMenu v-if="isAuthenticated" />
      <TheSearchMembers />
    </div>
  </nav>
</template>
