<script setup>
/**
 * Import packages
 */
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useFocus } from '@vueuse/core';
// import { vOnClickOutside } from '@vueuse/components';

/**
 * Import stores
 */
import { useAppStore } from '@/stores/app';
import { useCharacterStore } from '@/stores/character';

/**
 * Import utils
 */
import toLowerString from '@/utils/toLowerString';

/**
 * Component Name
 */
defineComponent({
  name: 'TheSearchMembers',
});

/**
 * On component mount
 */
onMounted(async () => {
  await characterStore.getAllCharactersNames();
});

/**
 * Variables
 */
const appStore = useAppStore();
const characterStore = useCharacterStore();

const modelValue = ref('');
const hasFocus = ref(false);
const filteredMembers = ref([]);
const showList = ref(false);
const search = ref();
let { focused } = useFocus(search);
const checked = ref(false);

/**
 * Functions
 */
const clear = () => {
  modelValue.value = '';
  filteredMembers.value = [];
  showList.value = false;
};

const showBox = () => {
  checked.value = true;
  setTimeout(() => {
    focused.value = true;
  }, 500);
};

const removeDiacritics = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const onKeyUp = e => {
  modelValue.value = e.target.value;

  if (modelValue.value.length > 1) {
    const searchQuery = removeDiacritics(modelValue.value.toLowerCase());
    filteredMembers.value = characterStore.charactersNames.filter(member =>
      removeDiacritics(member.name.toLowerCase()).includes(searchQuery)
    );
    showList.value = true;
  }

  if (modelValue.value.length === 0) {
    clear();
  }
};

const onKeyDown = e => {
  if (e.key === 'Escape') {
    clear();
  }
};

watch(hasFocus, async newValue => {
  if (!newValue) {
    setTimeout(() => {
      clear();
      checked.value = false;
    }, 500);
  }
});
</script>

<template>
  <Teleport to="body" :disabled="!appStore.isMobile && !appStore.isTablet">
    <div class="search-members">
      <input
        id="search-members__checkbox"
        class="search-members__checkbox"
        :checked="checked"
        type="checkbox"
        name="menu"
        @click="showBox"
      />
      <label for="search-members__checkbox" class="search-members__label">
        <VIcon class="search-members__icon" name="magnifying-glass-duotone" />
      </label>

      <div :class="[{ 'search-members__box--show': checked }, 'search-members__box']">
        <input
          id="search"
          ref="search"
          v-model="modelValue"
          class="search-members__input"
          type="search"
          placeholder="Search members"
          autocomplete="off"
          @keyup="onKeyUp"
          @keydown="onKeyDown"
          @focus="hasFocus = true"
          @blur="hasFocus = false"
        />
        <ul class="search-members__list" :class="{ 'search-members__list--show': showList }">
          <li v-for="member in filteredMembers.slice(0, 10)" :key="member" class="search-members__item">
            <router-link
              class="search-members__link"
              :to="{ name: 'Member/Detail', params: { member: member.name } }"
              :class="'class-' + toLowerString('-', member.class)"
            >
              <span class="search-members__text">{{ member.name }} </span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </Teleport>
</template>
