<script setup>
import { defineComponent } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'TheMobileMenu',
});

/**
 * Variables
 */
const menuItems = [
  { name: 'Roster', routerName: 'Roster', icon: 'users-three-duotone' },
  // { name: 'DKP', routerName: 'DKP', icon: 'scales-duotone' },
  // { name: 'Loot', routerName: 'Loot', icon: 'crown-duotone' },
  { name: 'Reports', routerName: 'Reports', icon: 'presentation-chart-duotone' },
];
</script>

<template>
  <ul class="main-menu">
    <MainMenuItem :menu-items="menuItems" />
  </ul>
</template>
