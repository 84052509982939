<script setup>
/**
 * Import packages
 */
import { defineComponent } from 'vue';

/**
 * Component Name
 */
defineComponent({
  name: 'TheFooter',
});

/**
 * Variables
 */
const currentYear = new Date().getFullYear();
</script>

<template>
  <footer class="app__footer">
    <div class="app__footer__inner">
      <div class="copyright">
        Stalkers of Time &copy; {{ currentYear }} | Created by
        <a class="copyright__link" href="https://fery.sk">Fery.sk</a>
      </div>
    </div>
  </footer>
</template>
