import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@vueuse/head';

import VueGtag from 'vue-gtag';

import '@/lib/tooltips.js';

import App from '@/App.vue';
import router from '@/router';

/**
 * Cleare console after HMR update
 */
if (import.meta.hot) {
  import.meta.hot.on('vite:beforeUpdate', () => console.clear());
}

const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

const app = createApp(App);

app
  .use(
    VueGtag,
    {
      config: { id: import.meta.env.VITE_GOOGLE_ANALYTICS_TAG },
    },
    router
  )
  .use(pinia)
  .use(router)
  .use(createHead())
  .mount('#app');
