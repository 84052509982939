import axios from 'axios';

import text from '@/stores/_text';

export default (url = import.meta.env.VITE_API_URL) => {
  return axios.create({
    baseURL: url,
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(text.AUTH_TOKEN_KEY_NAME),
    },
  });
};

export const wcLogsApi = (url = import.meta.env.VITE_API_WLOGS_URL) => {
  return axios.create({
    baseURL: url,
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(text.WL_TOKEN_KEY_NAME),
    },
  });
};

export const wcLogsApiToken = (url = import.meta.env.VITE_API_WLOGS_TOKEN_URL) => {
  return axios.request({
    method: 'POST',
    baseURL: url,
    headers: {
      'Content-type': 'application/json',
    },
    data: JSON.stringify({
      grant_type: 'client_credentials',
      client_id: import.meta.env.VITE_API_WLOGS_CLIENT_ID,
      client_secret: import.meta.env.VITE_API_WLOGS_CLIENT_SECRET,
    }),
  });
};
