<script setup>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { useAppStore } from '@/stores/app';

const appStore = useAppStore();
const router = useRouter();

/**
 * Component Name
 */
defineComponent({
  name: 'MainHeader',
});

const goBack = () => {
  document.getElementById('header').scrollIntoView({ top: 0, behavior: 'smooth' });
  router.go(-1);
};
</script>

<template>
  <div class="main-header">
    <Transition name="slide-in-right" appear>
      <button v-if="appStore.showBackButton" class="main-header__back" @click="goBack()">
        <VIcon class="main-header__back__icon" name="caret-left-bold" title="Go Back" />
      </button>
    </Transition>
    <Transition name="fade" mode="out-in">
      <template v-if="appStore.isTablet">
        <h1 v-if="appStore.isSectionTitleVisible" class="main-header__title">
          {{ appStore.appName }}
        </h1>
        <h2 v-else class="main-header__page-title">{{ appStore.page.title }}</h2>
      </template>
      <template v-else>
        <h1 class="main-header__title">
          {{ appStore.appName }}
        </h1>
      </template>
    </Transition>
    <p class="main-header__description">{{ appStore.appDescription }}</p>
  </div>
</template>
